<template>
    <div class="row dashboard">
        <div class="col-12">
            <div class="row gy-3">
                <div v-if="activeUser.id && !activeUser.is_verified" class="col-lg-12">
                    <user-email-verification />                   
                </div>
                <div class="col-12">
                    <h5 class="text-primary">Hi {{activeUser.first_name}}!</h5>
                </div>
                <div class="col-12">
                    <div class="card">
                        <div class="bg-holder bg-card" style="background-image:url(assets/img/icons/spot-illustrations/corner-1.png);"></div>
                        <!--/.bg-holder-->
                        <div class="card-body position-relative">
                            <p class="mb-0">Logos, websites, packaging design and more. Our trusted designer community has helped thousands of businesses launch, grow, expand and rebrand with custom, professional design.</p>
                            <a class="btn btn-primary mt-3 me-3" target="_blank" :href="absoluteUrl('/categories')">
                                Start a project
                                <i class="mdi mdi-chevron-right"></i>
                            </a>
                            <a class="btn btn-outline-primary mt-3" target="_blank" :href="absoluteUrl('/how-it-works')">
                                How it works
                                <i class="mdi mdi-chevron-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row py-4">
                <div class="col-12">
                    <order-list />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import OrderList from "./orders/OrderList.vue"
import UserEmailVerification from "@/components/UserEmailVerification.vue"

export default {
    name: 'dashboard',
    components:{
        OrderList,
        UserEmailVerification,
    },
    computed:{
        activeUser(){
            return this.$store.state.activeUser
        },
    },
}
</script>

<style scoped>
.user-photo{
    width:9rem;
}
@media(max-width:567px){
    .user-photo{
        width:4rem;
    }
}
.dashboard .table.profile-table td, .dashboard .table.profile-table th{
    padding:0.1rem 0.2rem;
}

</style>
